import * as React from 'react';
import { InjectedTranslateProps, translate } from 'react-i18next';
import { Text } from 'wix-ui-tpa/Text';
import { Input } from 'wix-ui-tpa/Input';
import { Button, PRIORITY } from 'wix-ui-tpa/Button';
import styles from './CreateGroupModal.st.css';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';
import { GroupLogoUpload } from '../../GroupLogoUpload/intex';
import { CreateGroupAction } from '../../App/types';
import { GroupTypesDropdown } from './GroupTypesDrowdown';
import { Loader, Modal } from '@wix/social-groups-common';
import withGroups from '../../../contexts/withGroups';
import { DATA_HOOKS } from './dataHooks';
import { compose } from '@wix/social-groups-common/dist/src/compose';
import { WithGroupsProps } from '../../../contexts/withGroupsProps';
const SOCIAL_GROUP_NAME_MAX_LENGTH = 50;
const DEFAULT_GROUP_PRIVACY_LEVEL = ApiTypes.v1.GroupPrivacyLevel.PUBLIC;
const PRIVACY_LEVELS: ApiTypes.v1.GroupPrivacyLevel[] = [
  ApiTypes.v1.GroupPrivacyLevel.PUBLIC,
  ApiTypes.v1.GroupPrivacyLevel.PRIVATE,
  ApiTypes.v1.GroupPrivacyLevel.SECRET,
];

export interface CreateGroupModalProps extends CreateGroupAction {
  onRequestClose(): void;
}

interface CreateGroupState {
  title: string;
  privacyLevel: ApiTypes.v1.GroupPrivacyLevel;
}

export class CreateGroupModal extends React.Component<
  CreateGroupModalProps & InjectedTranslateProps & WithGroupsProps,
  CreateGroupState
> {
  readonly state = {
    title: '',
    privacyLevel: DEFAULT_GROUP_PRIVACY_LEVEL,
  };
  private groupImage: File;

  handleOnSubmit = e => {
    e.preventDefault();
    const { title, privacyLevel } = this.state;
    if (this.isValidTitle() && !this.props.isGroupCreating) {
      this.props.createGroup({ title }, { privacyLevel }, this.groupImage);
    }
  };

  isValidTitle() {
    const { title } = this.state;
    return !!title;
  }

  setSocialGroupName = e => {
    this.setState({
      title: e.target.value,
    });
  };

  setSocialGroupType = option => {
    this.setState({ privacyLevel: option.id });
  };

  render() {
    const { t, onRequestClose, isGroupCreating } = this.props;
    const { title, privacyLevel } = this.state;

    const isDisabledCreateButton = !this.isValidTitle() || isGroupCreating;
    return (
      <form
        {...styles(styles.root, {})}
        data-hook={DATA_HOOKS.root}
        onSubmit={this.handleOnSubmit}
      >
        <Modal.Header title={t('groups-web.modals.create-group.title')} />

        <GroupLogoUpload uploadImage={this.setImage} />

        <div className={styles.nameControl}>
          <Text {...styles(styles.label, {})}>
            <span>{t('groups-web.modals.create-groups.groupName')}</span>
            <span {...styles(styles.counter, {})}>
              {title.length}/{SOCIAL_GROUP_NAME_MAX_LENGTH}
            </span>
          </Text>
          <Input
            data-hook={DATA_HOOKS.groupName}
            maxLength={SOCIAL_GROUP_NAME_MAX_LENGTH}
            placeholder={t(
              'groups-web.modals.create-groups.groupName.placeholder',
            )}
            onChange={this.setSocialGroupName}
          />
        </div>

        <div className={styles.privacyControl}>
          <Text {...styles(styles.label, {})}>
            <span>{t('groups-web.modals.create-groups.groupType')}</span>
          </Text>
          <div>
            <GroupTypesDropdown
              selectedType={privacyLevel}
              onSelect={this.setSocialGroupType}
            />
          </div>
        </div>

        <Modal.Buttons>
          <Button
            data-hook={DATA_HOOKS.cancelButton}
            priority={PRIORITY.secondary}
            className={styles.cancelButton}
            onClick={onRequestClose}
          >
            {t('groups-web.btn.cancel')}
          </Button>
          <Button
            data-hook={DATA_HOOKS.createButton}
            priority={PRIORITY.primary}
            className={styles.createButton}
            type="submit"
            disabled={isDisabledCreateButton}
            prefixIcon={isGroupCreating && <Loader />}
          >
            {isGroupCreating
              ? t('groups-web.btn.creating')
              : t('groups-web.btn.create')}
          </Button>
        </Modal.Buttons>
      </form>
    );
  }

  private readonly setImage = (image: File) => {
    this.groupImage = image;
  };
}

const enhance = compose(withGroups, translate());

export default enhance(CreateGroupModal);
