import * as React from 'react';
import { InjectedTranslateProps, translate } from 'react-i18next';
import { PRIORITY } from 'wix-ui-tpa/Button';
import { compose } from '@wix/social-groups-common/dist/src/compose';

import {
  withAppSettings,
  WithAppSettingsProps,
} from '../../contexts/withAppSettings';

import { Grid, GridProps } from 'wix-ui-tpa/Grid';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';
import withGroups from '../../contexts/withGroups';

import { DEFAULT_GRID_CARD_SPACING, LayoutType } from '../../settings';
import { GroupCard } from '../GroupCard';
import { Button } from '../Button/Button';
import { DATA_HOOKS } from './dataHooks';
import { NoGroups } from './NoGroups';
import {
  BIUserEntry,
  tryToCallBi,
  withBiLogger,
  WithBiLoggerProps,
} from '@wix/social-groups-common/dist/src/context';

import styles from './GroupList.st.css';
import { WithGroupsProps } from '../../contexts/withGroupsProps';
import { getGroupUrl } from '../utils/utils';

const { PENDING } = ApiTypes.v1.GroupApprovalStatus;

export interface GroupListProps {
  onCreateGroupClick(): void;
  withCTA?: boolean;
  goToGroup(groupId: string): void;
  mobile: boolean;
  className?: string;
}

type Props = GroupListProps &
  InjectedTranslateProps &
  WithGroupsProps &
  WithAppSettingsProps &
  WithBiLoggerProps;

export const GroupListComponent: React.FC<Props> = props => {
  const {
    appSettings,
    biLogger,
    goToGroup,
    groupNameQuery,
    groups,
    mobile,
    onCreateGroupClick,
    t,
    withCTA,
    className,
    groupSectionUrl,
  } = props;
  const gapFromSettings = appSettings.layout.gridCardSpacing;
  const gap =
    typeof gapFromSettings === 'number'
      ? gapFromSettings
      : DEFAULT_GRID_CARD_SPACING;

  const listGrid = {
    maxColumns: 1,
    columnGap: 0,
    rowGap: 0,
  };
  const gridProps: { [key: string]: Partial<GridProps> } = {
    [LayoutType.grid]: {
      maxColumns: 3,
      columnGap: gap,
      rowGap: gap,
      maxColumnWidth: 300,
    },
    [LayoutType.list]: listGrid,
    [LayoutType.listWidget]: listGrid,
  };

  // TODO: fix all layout on mobile. Only list is working for now
  const layoutType = mobile
    ? LayoutType.list
    : appSettings.layout.groupListLayout;

  const existingGroups =
    groups && groups.length
      ? groups.filter(group => group.approvalStatus !== PENDING)
      : groups;

  if (!existingGroups) {
    // TODO: clarify with designers
    return <div>Loading...</div>;
  }

  if (!existingGroups.length) {
    if (groupNameQuery) {
      return (
        <NoGroups
          data-hook={DATA_HOOKS.noGroupsResult}
          emptyStateHeader={t('groups-web.search.no-results.title')}
          emptyStateText={t('groups-web.search.no-results.text')}
        />
      );
    }
    return (
      <NoGroups
        data-hook={DATA_HOOKS.noGroups}
        emptyStateHeader={t('groups-web.empty-state.header')}
        emptyStateText={t('groups-web.empty-state.text')}
        actionButton={
          withCTA ? (
            <Button
              priority={PRIORITY.primary}
              onClick={onCreateGroupClick}
              data-hook={DATA_HOOKS.noGroupsCreateButton}
            >
              {t('groups-web.empty-state.cta')}
            </Button>
          ) : null
        }
      />
    );
  }

  const onGoToGroup = (group: ApiTypes.v1.GroupResponse, event) => {
    tryToCallBi(async () => {
      await biLogger.groupsSelectGroupFromList({
        origin: event.target.dataset.biOrigin || 'group_block',
        group_id: group.groupId,
        userEntry: BIUserEntry.SITE,
      } as any);
    });
    goToGroup(group.slug);
  };

  return (
    <Grid
      {...gridProps[layoutType]}
      {...styles(styles.grid, { layoutType }, { className })}
    >
      {existingGroups.map(group => (
        <Grid.Item key={group.groupId} {...styles(styles.gridItem, {})}>
          <GroupCard
            group={group}
            goToGroup={event => onGoToGroup(group, event)}
            groupUrl={getGroupUrl(groupSectionUrl, group)}
            mobile={mobile}
          />
        </Grid.Item>
      ))}
    </Grid>
  );
};

const enhance = compose(withGroups, translate(), withAppSettings, withBiLogger);
export const GroupList = enhance(GroupListComponent) as React.ComponentType<
  GroupListProps
>;
