import * as React from 'react';
import { InjectedTranslateProps, translate } from 'react-i18next';
import { GroupList } from '../GroupList';
import CreateGroupModal from '../modals/CreateGroupModal/CreateGroupModal';
import { Spinner } from '@wix/social-groups-common/dist/src/components/Spinner';
import withGroups from '../../contexts/withGroups';
import {
  withAppSettings,
  WithAppSettingsProps,
} from '../../contexts/withAppSettings';
import withGroupsActions, {
  WithGroupsActionsProps,
} from '../../contexts/withGroupsActions';
import {
  InjectedWebOutProps,
  Modal,
  ProfilePrivacyDialog,
  WebOut,
  withAppToasts,
  withTPAConfig,
  withWebOut,
} from '@wix/social-groups-common';
import { WithAppToastsProps } from '@wix/social-groups-common/dist/src/types';
import { compose } from '@wix/social-groups-common/dist/src/compose';
import { canCreateGroup, isProfilePublic } from '@wix/social-groups-api';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';
import { DATA_HOOKS } from './dataHooks';
import {
  withCurrentUser,
  WithCurrentUserProps,
} from '../../contexts/withCurrentUser';
import {
  MemberInvites,
  tryToCallBi,
  withBiLogger,
  WithBiLoggerProps,
  withMemberInvites,
  withWixSiteMembers,
  WixSiteMember,
  WixSiteMemberActions,
} from '@wix/social-groups-common/dist/src/context';
import { WithGroupsProps } from '../../contexts/withGroupsProps';
import { UpdateProgress } from '@wix/social-groups-common/dist/src/components/ContentEditor/UpdateProgress';

const isApprovalStatusPending = group =>
  group.approvalStatus === ApiTypes.v1.GroupApprovalStatus.PENDING;

export interface GroupsProps {}

export interface GroupsState {
  isCreateGroupModalOpened: boolean;
  isGroupCreating: boolean;
  isProfileDialogOpen: boolean;
}

export type ComponentProps = InjectedTranslateProps &
  GroupsProps &
  WithCurrentUserProps &
  WithGroupsProps &
  WithGroupsActionsProps &
  WithAppToastsProps &
  WixSiteMember &
  WixSiteMemberActions &
  WithAppSettingsProps & { mobile: boolean } & InjectedWebOutProps &
  MemberInvites &
  WithBiLoggerProps;

export class GroupsSectionComponent extends React.Component<
  ComponentProps,
  GroupsState
> {
  readonly state: GroupsState = {
    isCreateGroupModalOpened: false,
    isGroupCreating: false,
    isProfileDialogOpen: false,
  };

  componentDidUpdate(
    prevProps: Readonly<ComponentProps>,
    prevState: Readonly<GroupsState>,
  ): void {
    if (!prevProps.promptPublicProfile && this.props.promptPublicProfile) {
      this.openProfileModal();
    }
  }

  componentDidMount() {
    tryToCallBi(async () => {
      await this.props.biLogger.groupsPageView({
        origin: 'list view',
      });
    });
  }

  closeCreateGroupModal = () => {
    this.setState({ isCreateGroupModalOpened: false });
  };

  emptyStateCreateGroupButton = () => {
    tryToCallBi(async () => {
      await this.props.biLogger.groupsCreateGroupClick({
        origin: 'empty_state_btn',
      });
    });

    this.openCreateGroupModal();
  };

  openCreateGroupModal = () => {
    if (this.isProfilePrivate()) {
      return this.openProfileModal();
    }
    if (this.props.currentUser.loggedIn) {
      this.setState({ isCreateGroupModalOpened: true });
    } else {
      this.props.actions.promptLogin();
    }
  };

  handleCreateGroupClick = () => {
    tryToCallBi(async () => {
      await this.props.biLogger.groupsCreateGroupClick({
        origin: 'plus_btn',
      });
    });

    const { mobile, openWebOutModal } = this.props;
    mobile ? openWebOutModal() : this.openCreateGroupModal();
  };

  render() {
    const {
      actions,
      createGroupPolicy,
      currentUser,
      loadMemberInvitesLink,
      t,
      isWebOutModalOpened,
      closeWebOutModal,
      mobile,
      memberInvitesLink,
      updateProgress,
    } = this.props;

    if (updateProgress === UpdateProgress.UPDATING) {
      return <Spinner offset="L" label={t('groups-web.group-list.loading')} />;
    }
    const { isCreateGroupModalOpened } = this.state;

    const shouldShowCreateGroupButton = canCreateGroup(
      currentUser,
      createGroupPolicy,
    );

    return (
      <>
        <GroupList
          data-hook={DATA_HOOKS.groupList}
          onCreateGroupClick={this.emptyStateCreateGroupButton}
          goToGroup={actions ? actions.goToGroup : null}
          withCTA={shouldShowCreateGroupButton}
          mobile={mobile}
        />
        {mobile ? (
          <WebOut
            inviteLink={memberInvitesLink}
            loadMemberInvitesLink={loadMemberInvitesLink}
            buttonLabelKey={t('groups-web.modal.out.button')}
            title={t('groups-web.modal.out.create-group.title')}
            caption={t('groups-web.modal.out.description')}
            isOpen={isWebOutModalOpened}
            onRequestClose={closeWebOutModal}
          />
        ) : (
          <Modal
            isOpen={isCreateGroupModalOpened}
            onRequestClose={this.closeCreateGroupModal}
          >
            <CreateGroupModal
              data-hook={DATA_HOOKS.createGroupModal}
              createGroup={actions && actions.createGroup}
              onRequestClose={this.closeCreateGroupModal}
            />
          </Modal>
        )}
        {this.isProfilePrivate() && (
          <ProfilePrivacyDialog
            onRequestClose={this.closeProfileModal}
            onChangeProfile={this.changeProfile}
            isOpen={this.state.isProfileDialogOpen}
          />
        )}
      </>
    );
  }

  private openProfileModal() {
    this.setState({ isProfileDialogOpen: true });
  }

  private readonly changeProfile = () => {
    const { makeProfilePublic, currentSiteMember } = this.props;
    currentSiteMember && makeProfilePublic(currentSiteMember.id);
  };
  private readonly closeProfileModal = () =>
    this.setState({ isProfileDialogOpen: false });

  private isProfilePrivate() {
    return (
      this.props.currentSiteMember &&
      !isProfilePublic(this.props.currentSiteMember)
    );
  }
}

const enhance = compose(
  withGroups,
  withCurrentUser,
  withGroupsActions,
  withAppToasts,
  withWixSiteMembers,
  withAppSettings,
  translate(),
  withTPAConfig,
  withWebOut,
  withMemberInvites,
  withBiLogger,
);
export const GroupsSection = enhance(
  GroupsSectionComponent,
) as React.ComponentType<GroupsProps>;
