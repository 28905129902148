import * as React from 'react';
import { Group } from '@wix/social-groups-api';
import { InjectedTranslateProps, translate } from 'react-i18next';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';
import {
  withAppSettings,
  WithAppSettingsProps,
} from '../../contexts/withAppSettings';

import { RoleBadges } from '@wix/social-groups-common';
import { compose } from '@wix/social-groups-common/dist/src/compose';
import { GroupPrivacyLevel } from './GroupPrivacy';
import styles from './GroupCard.st.css';
import { MembersLabelWithCount } from '../MembersLabel/MembersLabelWithCount';
import {
  showAdminBadge,
  showGroupType,
  showMemberCount,
} from '../../settings/utils';

interface GroupCardMetaProps {
  group: ApiTypes.v1.GroupResponse;
  groupUrl: string;
}

type Props = GroupCardMetaProps & InjectedTranslateProps & WithAppSettingsProps;
export const GroupCardMetaComponent: React.FC<Props> = ({
  t,
  group,
  appSettings,
  groupUrl,
}) => {
  const { relationship, roles, slug } = group;
  const groupWrapper = Group.getGroupResponseWrapper(group);
  const memberCount = groupWrapper.getMembersCount();
  const pendingMembersCount = groupWrapper.getPendingMembersCount();
  const membersName = groupWrapper.getMembersName();
  const privacyLevel = groupWrapper.getPrivacy();
  const showMembersCount = showMemberCount(appSettings);

  const groupType = showGroupType(appSettings);
  return (
    <div className={styles.meta}>
      <div className={styles.header}>{renderTitleLink()}</div>
      <p className={styles.details}>
        {groupType && <GroupPrivacyLevel t={t} privacyLevel={privacyLevel} />}
        {showMembersCount && groupType && <span>&nbsp;&middot;&nbsp;</span>}
        {showMembersCount && renderMembersCount()}
        {showMembersCount && renderPendingMembersCount()}
      </p>
    </div>
  );

  function renderMembersCount() {
    return (
      <MembersLabelWithCount
        groupMemberLabel={membersName}
        count={memberCount}
      />
    );
  }

  function renderPendingMembersCount() {
    return pendingMembersCount ? (
      <span>
        &nbsp;&middot;&nbsp;
        <span className={styles.pendingMembers}>
          {t('groups-web.group-list.pending-members.count', {
            count: pendingMembersCount,
          })}
        </span>
      </span>
    ) : null;
  }

  function renderTitleLink() {
    return (
      <>
        <a data-hook="title-link" className={styles.link} href={groupUrl}>
          <span className={styles.title} data-bi-origin="group_title">
            {group.details.title}
          </span>
          {showAdminBadge(appSettings) && (
            <RoleBadges
              relationship={relationship}
              roles={roles}
              {...styles(styles.badge, {})}
            />
          )}
        </a>
      </>
    );
  }
};

const enhance = compose(translate(), withAppSettings);

export const GroupCardMeta = enhance(GroupCardMetaComponent);
