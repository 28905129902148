import * as React from 'react';
import { GroupPageWithStyles } from '../components/App/types';
import i18n from '../config/i18n';
import { ExperimentsProvider } from '@wix/wix-experiments-react';
import { TPAComponentsProvider } from 'wix-ui-tpa/TPAComponentsConfig';
import { AppSettingsContext } from './AppSettingsContext';
import { GroupsProvider } from './GroupsContext';
import { GroupsActionsProvider } from './GroupsActionsContext';
import { CurrentUserProvider } from './CurrentUserContext';
import { I18nextProvider } from 'react-i18next';
import { CommonTPAComponentsProvider } from '@wix/social-groups-common'; //TODO: refactor common context
import {
  BiContextProvider,
  WixSiteMembersContext,
} from '@wix/social-groups-common/dist/src/context';
import styles from '../components/App/App.st.css';

type GroupsAppProps = React.PropsWithChildren<GroupPageWithStyles>;

export const GroupsAppProvider: React.FunctionComponent<GroupsAppProps> = (
  props: GroupsAppProps,
) => {
  const {
    actions,
    createGroupPolicy,
    currentUser,
    experiments,
    groupNameQuery,
    groups,
    groupsSortBy,
    host: { formFactor },
    isGroupCreating,
    language,
    translation,
    currentSiteMember,
    promptPublicProfile,
    appSettings,
    biLogger,
    children,
    navigatingToGroup,
    groupSectionUrl,
    updateProgress,
  } = props;

  const isMobile = formFactor === 'Mobile';
  const makeProfilePublic = actions && actions.makeProfilePublic;
  return (
    <I18nextProvider i18n={i18n({ language, translation })}>
      <ExperimentsProvider options={{ experiments }}>
        <TPAComponentsProvider value={{ mobile: isMobile }}>
          <CommonTPAComponentsProvider value={{ mobile: isMobile }}>
            <AppSettingsContext.Provider value={appSettings}>
              <GroupsProvider
                value={{
                  groups,
                  isGroupCreating,
                  groupNameQuery,
                  groupsSortBy,
                  createGroupPolicy,
                  promptPublicProfile,
                  navigatingToGroup,
                  groupSectionUrl,
                  updateProgress,
                }}
              >
                <GroupsActionsProvider value={actions || ({} as any)}>
                  <CurrentUserProvider value={currentUser}>
                    <WixSiteMembersContext.Provider
                      value={{
                        currentSiteMember,
                        makeProfilePublic,
                      }}
                    >
                      <BiContextProvider value={biLogger}>
                        <div
                          data-hook="groups"
                          {...styles('root', {
                            navigating: !!navigatingToGroup,
                          })}
                        >
                          {children}
                        </div>
                      </BiContextProvider>
                    </WixSiteMembersContext.Provider>
                  </CurrentUserProvider>
                </GroupsActionsProvider>
              </GroupsProvider>
            </AppSettingsContext.Provider>
          </CommonTPAComponentsProvider>
        </TPAComponentsProvider>
      </ExperimentsProvider>
    </I18nextProvider>
  );
};

GroupsAppProvider.displayName = 'GroupsAppProvider';
