import * as React from 'react';
import { translate, InjectedTranslateProps } from 'react-i18next';
import { withTPAConfig } from '@wix/social-groups-common';
import { compose } from '@wix/social-groups-common/dist/src/compose';

import {
  WithAppSettingsProps,
  withAppSettings,
} from '../../contexts/withAppSettings';
import { LayoutType } from '../../settings';

import { GroupCardProps, GroupListCardProps } from './types';
import { GroupGridCard } from './GroupGridCard';
import { GroupListCard } from './GroupListCard';
import { showGroupLogo } from '../../settings/utils';

type Props = WithAppSettingsProps &
  InjectedTranslateProps &
  Omit<GroupCardProps, 'imageRatio'>;

export const GroupCardComponent: React.FC<Props> = ({
  appSettings,
  ...props
}) => {
  let Component: React.ComponentType<GroupCardProps | GroupListCardProps>;

  const imageRatio = appSettings.layout.imageRatio;
  const layout = appSettings.layout.groupListLayout;

  const specificProps = {
    [LayoutType.list]: {
      hasSpacing: !!appSettings.layout.listCardSpacing,
    },
    [LayoutType.grid]: {},
  };

  switch (layout) {
    case LayoutType.grid:
      Component = GroupGridCard;
      Component.displayName = 'GroupGridCard';
      break;
    case LayoutType.list:
    default:
      Component = GroupListCard;
      Component.displayName = 'GroupListCard';
  }

  return (
    <Component
      {...props}
      imageRatio={imageRatio}
      showGroupLogo={showGroupLogo(appSettings)}
      layout={layout}
      {...specificProps[layout]}
    />
  );
};

const enhance = compose(translate(), withAppSettings, withTPAConfig);
export const GroupCard = enhance(GroupCardComponent) as React.ComponentType<
  Omit<GroupCardProps, 'imageRatio'>
>;
GroupCard.displayName = 'GroupCard';
