import * as React from 'react';

import { LayoutType, ImageRatio } from '../../settings';
import styles from './GroupCardMedia.st.css';
import { GroupCardProps } from './types';
import {
  withAppSettings,
  WithAppSettingsProps,
} from '../../contexts/withAppSettings';
import { showGroupLogo } from '../../settings/utils';

export interface GroupCardMediaProps {
  url: string;
  layout: LayoutType;
  ratio: ImageRatio;
  groupUrl: string;
  goToGroup?: GroupCardProps['goToGroup'];
}
const noop = () => {};

export const GroupCardMediaComponent: React.FC<GroupCardMediaProps &
  WithAppSettingsProps> = ({ url, goToGroup, appSettings, groupUrl }) => {
  if (!showGroupLogo(appSettings)) {
    return null;
  }
  return (
    <a
      href={groupUrl}
      onClick={goToGroup}
      data-hook="group-card-media"
      data-bi-origin="group_image"
      style={{ backgroundImage: `url(${url})` }}
      {...styles(styles.image, {})}
    />
  );
};
GroupCardMediaComponent.displayName = 'GroupCardMediaComponent';
GroupCardMediaComponent.defaultProps = {
  goToGroup: noop,
};

export const GroupCardMedia = withAppSettings(GroupCardMediaComponent);
