import * as React from 'react';
import { GroupsAppProvider } from '../../contexts/GroupsAppProvider';
import { GroupPageWithStyles } from '../App/types';
import { GroupsSection } from '../Groups/GroupsSection';
import styles from './WidgetRoot.st.css';
// TODO: refactor for GroupWidgetWithStyles interface
export const WidgetRoot: React.FunctionComponent<GroupPageWithStyles> = (
  props: GroupPageWithStyles,
) => {
  return (
    <div {...styles('root')}>
      <GroupsAppProvider {...props}>
        <GroupsSection />
      </GroupsAppProvider>
    </div>
  );
};

WidgetRoot.displayName = 'WidgetRoot';
