import * as React from 'react';
import { InjectedTranslateProps } from 'react-i18next';
import classnames from 'classnames';
import { LayoutType } from '../../settings';
import { GroupCardMedia } from './GroupCardMedia';
import { GroupListCardProps } from './types';
import { GroupCardMeta } from './GroupCardMeta';
import { GroupCardCTA } from './GroupCardCTA';
import styles from './GroupCard.st.css';

type Props = InjectedTranslateProps & GroupListCardProps;
export const GroupListCard: React.FC<Props> = ({
  group,
  goToGroup,
  imageRatio,
  hasSpacing,
  mobile,
  cardMetaComponent: MetaComponent, //TODO: Types broken! refactor? polymorphism? should have one interface
  cardActionsComponent: CardActionsComponent,
  showGroupLogo,
  layout,
  groupUrl,
  ...props
}) => {
  return (
    <div
      {...styles(
        styles.root,
        {
          layout: LayoutType.list,
          imageRatio,
          hasSpacing,
          mobile,
          withItemClick: !!goToGroup,
        },
        { className: classnames(styles[layout], props.className) },
      )}
      onClick={goToGroup ? goToGroup : undefined}
      data-bi-origin="group_image"
      data-hook="group-list-item"
    >
      <div className={styles.mediaWithMeta}>
        {showGroupLogo && (
          <div className={styles.mediaContainer}>
            <GroupCardMedia
              url={group.details.logoUrl}
              layout={LayoutType.list}
              ratio={imageRatio}
              groupUrl={groupUrl}
            />
          </div>
        )}
        <MetaComponent group={group} groupUrl={groupUrl} />
      </div>
      <CardActionsComponent group={group} groupUrl={groupUrl} />
    </div>
  );
};

GroupListCard.defaultProps = {
  cardMetaComponent: GroupCardMeta,
  cardActionsComponent: GroupCardCTA,
};
