import * as React from 'react';
import { InjectedTranslateProps } from 'react-i18next';
import { Card } from 'wix-ui-tpa/Card';
import { ImageRatio, LayoutType } from '../../settings';
import { GroupCardMedia } from './GroupCardMedia';
import { GroupCardProps } from './types';
import { GroupCardMeta } from './GroupCardMeta';
import { GroupCardCTA } from './GroupCardCTA';

import styles from './GroupCard.st.css';

const getMediaAspectRatios: { [K in ImageRatio]: number } = {
  [ImageRatio.square]: 1,
  [ImageRatio.rectangle]: 16 / 9,
};

export const GroupGridCard: React.FC<InjectedTranslateProps &
  GroupCardProps> = ({
  group,
  goToGroup,
  t,
  imageRatio,
  mobile,
  showGroupLogo,
  groupUrl,
  ...props
}) => (
  <Card
    stacked
    mediaAspectRatio={getMediaAspectRatios[imageRatio]}
    media={
      showGroupLogo ? (
        <GroupCardMedia
          url={group.details.logoUrl}
          layout={LayoutType.grid}
          ratio={imageRatio}
          groupUrl={groupUrl}
          goToGroup={goToGroup}
        />
      ) : (
        undefined
      )
    }
    info={
      <div
        {...styles(styles.info, {})}
        data-hook="group-card-info"
        onClick={goToGroup}
      >
        <GroupCardMeta group={group} groupUrl={groupUrl} />
        <GroupCardCTA group={group} groupUrl={groupUrl} />
      </div>
    }
    data-hook="group-list-item"
    {...props}
    {...styles(styles.root, {
      layout: LayoutType.grid,
      imageRatio,
      mobile,
      showGroupLogo,
    })}
  />
);
GroupGridCard.displayName = 'GroupGridCard';
