import * as React from 'react';
import { Button as TPAButton, ButtonProps } from 'wix-ui-tpa/Button';

import {
  withAppSettings,
  WithAppSettingsProps,
} from '../../contexts/withAppSettings';

import styles from './Button.st.css';

const ButtonComponent: React.FC<WithAppSettingsProps & ButtonProps> = ({
  appSettings,
  ...props
}) => (
  <TPAButton
    {...props}
    {...styles('root', { type: appSettings.design.buttonType }, props)}
  />
);

export const Button = withAppSettings(ButtonComponent);
