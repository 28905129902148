import { AppSettingsContextValue } from '../contexts/AppSettingsContextValue';

function getDisplaySettings(settings: AppSettingsContextValue) {
  return settings && settings.display;
}

function isUndefinedOrTrue(value: any) {
  return value === undefined || !!value;
}

export const showGroupCTA = (settings: AppSettingsContextValue) => {
  const display = getDisplaySettings(settings);
  return display && isUndefinedOrTrue(display.showButton);
};

export const showGroupLogo = (settings: AppSettingsContextValue) => {
  const display = getDisplaySettings(settings);
  return display && isUndefinedOrTrue(display.showImage);
};

export const showGroupType = (settings: AppSettingsContextValue) => {
  const display = getDisplaySettings(settings);
  return display && isUndefinedOrTrue(display.showGroupType);
};

export const showMemberCount = (settings: AppSettingsContextValue) => {
  const display = getDisplaySettings(settings);
  return display && display.showMemberCount;
};

export const showAdminBadge = (settings: AppSettingsContextValue) => {
  const display = getDisplaySettings(settings);
  return display && display.showAdminBadge;
};
